<template>
  <v-container>
    <h1>Alexandria Polyglot</h1>
  </v-container>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'About'
}
</script>

<style scoped>

</style>
